.App {
  text-align: center;
  
}
p {
  white-space: pre-line;
}

/*NAVBAR STYLING*/
nav {
  display: flex;  
  justify-content: space-between;
  background-color: #27282D;
  padding: 2vw 5vw 2vw;
  align-items: center;
  position: sticky;
  top: 0;
  border-bottom: 0.75vw solid #3AA2C2;
  z-index: 1000;
}
.links {
  display: flex;
  justify-content: space-around;
  gap: 3vw;
}
.links a {
  font-size: 1.5vw;
  text-decoration: none;
  color: white;
}
.links a:hover {
  color: #3AA2C2;
  transition: 0.7s;
}
#drop-menu {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
#drop-menu img {
  max-width: 50px;
  padding-right: 1vw;
}
#drop-menu label {
  color: white;
  padding-right: 1vw;
}


/*HEADER STYLING*/
#header {
  padding:10vw;
  color: #27282D;
}
#header img {
  padding: 5vw;
  width: 40vw;
  min-width: 300px;
}
#header h2 {
  font-style: italic;
}


/*PROJECTS STYLING*/
#projects {
  padding:10vw;
  background-color: #27282D;;
  text-align: left;
  color: white;
}
#projects h2 {
  padding-bottom: 10vw;
}
#projects img {
  margin-left: auto;
  margin-right: auto;
  width: 50vw;
  min-width: 280px;
}

/*SKILLS STYLING*/
#skills {
  padding: 10vw;
  text-align: left;  
  color: #27282D;  
}
#skills h1 {
  padding-bottom: 20px;
}
#skills-icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 30px;    
}
#skills img {
  width: 50px;
  margin: 0;
}
#skills img:hover {
  filter: drop-shadow(3px 3px 3px gray);
}

/*ABOUT STYLING*/
#about {
  padding: 10vw;
  text-align: left;
  background-color: #27282D;
  color:white;
}
#about h1 {
  filter: drop-shadow(3px 3px 3px gray);
  padding-bottom: 4vw;
}

/*CONTACT STYLING*/
#contact {
  padding: 10vw;
  color: #27282D;
  text-align: center;
}
#contact > h1 {
  text-align: left;
  filter: drop-shadow(3px 3px 3px gray);
  margin-bottom: 5vw;
}
form {
  border-radius: 15px;
  text-align: center;
  background-color: #27282D;
  color: white;
  padding: 5vw 10vw 5vw 10vw;  
  margin-left: 9vw;
  margin-right: 9vw;
}  
.error {
  color: red;  
  height: 50px;  
  text-align: left;
}
#contact label {
  min-width: 160px;  
  padding: 0;
  margin: 0;
  text-align: left;
}
.input {
  width: 40vw;
  min-width: 160px;
  border:solid #3AA2C2;
  border-radius: 15px;  
}
.input-box{
  text-align: left;
}
textarea  {
  height: 300px;
}
#submit {
  background-color: #3AA2C2;
  color: #27282D;
  border-radius:10px;
  border: none;
  font-weight: bold;
  font-size: large;
  width: 160px;
  height: 50px;
  box-shadow: 0px 0px 2px 2px #3AA2C2;
  margin-top: 4vw;
}
#submit:hover {
  background-color:white;
  transition: 0.7s;
}
#submit:active {
  background-color: #27282D;
  color: white;
  transition: 0.7s;
}

/*FOOTER STYLING*/
footer {
  background-color: #27282D;
  color: white;
  display: flex;
  justify-content: flex-start;  
  gap: 3vw;
  padding: 2vw 10vw 2vw;
  border-top: 0.75vw solid #3AA2C2;
}
footer a {
  text-decoration: none;
  color: white;
  font-size: small;
  padding-left: 3vw;
}
footer a:hover {
  color: #3AA2C2;
  transition: 0.7s;
}
footer img {
  width: 5vw;  
  min-width: 50px;
  margin: -2vw;  
}

